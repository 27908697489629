/** @jsx jsx */
import { jsx, Flex, Link, Text, Grid } from "theme-ui"
import Sticky from './sticky.js'
import Scroll from './scroll.js'
import { FaGitlab, FaLinkedin, FaCodepen } from 'react-icons/fa';
import colors from "../gatsby-plugin-theme-ui/colors"


export const scale = (num, outMin, outMax) => {
  return ((num - 0) * (outMax - outMin)) / (1 - 0) + outMin
}


const Words = props => (
  <Text
  {...props}
    sx={{
    display: 'block',
    textAlign: "center",
    fontSize: "40px",
    fontFamily: "Montserrat",
    color: `${colors.white}`,
    position: "relative"
  }}
  />
)


const Socials = props => {

  return (
    <Sticky
    boxShadow= "1px 1px 50px pink"
    height="2000px"
    background={colors.glow430}
    cover
    render={({ progress }) => {
      return (
        <Grid
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Scroll></Scroll>
          <Grid>
            <Words as="h2">Find me in these places </Words>
            <Flex
              as="nav"
              sx={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Link
                aria-label="linkedin profile for Dee Bryant"
                target="_blank"
                href="https://www.linkedin.com/in/deebryant/"
                px={4}
                py={4}
                sx={{ position: "relative", color: "white", fontSize: "40px" }}
              >
                <FaLinkedin aria-hidden="true" />
              </Link>
              <Link
                aria-label="Codepen profile for Dee Bryant"
                target="_blank"
                href="https://codepen.io/tolkadot/"
                px={4}
                py={4}
                sx={{ position: "relative", color: "white", fontSize: "40px" }}
              >
                <FaCodepen aria-hidden="true" />
              </Link>
              <Link
                target="_blank"
                aria-label="Github profile for Dee Bryant"
                href="https://gitlab.com/tolka-web"
                px={4}
                py={4}
                sx={{ position: "relative", color: "white", fontSize: "40px" }}
              >
                <FaGitlab aria-hidden="true" />
              </Link>
            </Flex>
          </Grid>
        </Grid>
      )
    }}
    />
    )
  }



export default Socials
