/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import MainHeadingAnimated from "../components/mainHeadingAnimated.js"
import Intro from "../components/intro.js"
import Socials from "../components/socials.js"

const IndexPage = () => (


<Grid sx={{
  gridGap:"0"
}}>
    <Grid sx={{
    gridGap:"0"
  }}>
      <MainHeadingAnimated text="hello"></MainHeadingAnimated>
  </Grid>

  <Grid sx={{
        gridGap:"0",
        minHeight: '1500px'
  }}>
    <Intro ></Intro>
    <Socials ></Socials>
  </Grid>

</Grid>



)

export default IndexPage
