import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import colors from "../gatsby-plugin-theme-ui/colors"

import { Flex } from "theme-ui"

const Scroll = () => {
  const [show, doShow] = useState({
    itemOne: '0px' //set initial height of div to 0px
  });
  const ourRef = useRef(null)

  //console.log(show.itemOne)

  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top;

    const div1Pos = topPos(ourRef.current);
    //const scrollupTrgger = div1Pos - 20;

    //console.log(div1Pos)

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight; //Get scroll potision
       //console.log(div1Pos , scrollPos, scrollupTrgger)
      // let turnedon = false;
      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, itemOne: '1000px' }));

      }
      else if (div1Pos > scrollPos) {
        doShow(state => ({ ...state, itemOne: '0px' }));
      }
    };


    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  
  return (
    <>
      <Flex>
       <Div animate={show.itemOne} ref={ourRef}  />
      </Flex>
    </>
  );

};

export default Scroll

const Div = styled.div`
  opacity: 1;
  transition: all 8s;
  height: ${props  => props.animate};
  width: ${props  => props.animate};
  border-radius: 50%;
  background-color: ${colors.glow4};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)

`;
