/** @jsx jsx */
import  { useState} from "react"
import { jsx, Heading, Box, Grid, Flex, Text } from "theme-ui"
import { keyframes } from '@emotion/react'

import styled from '@emotion/styled'
import { motion } from "framer-motion"


const sparkle = keyframes({
  '0%': { textShadow: '0 0 19px #ff6600, 0 0 20px #FF7233, 0 0 27px #ff7f66, 1px 5px 30px #ff8c99, -1px -5px 30px #ff99cc' },
  '75%': {textShadow: 'none'},
  '100%':  {textShadow: '0 0 19px #ff6600, 0 0 20px #FF7233, 0 0 27px #ff7f66, 1px 5px 30px #ff8c99, -1px -5px 30px #ff99cc'},
})

const fadein = keyframes({
  '0%': {opacity: '0',},
  '100%':  {opacity: '1',},
})

const bounce = keyframes({
  '0%': {transform: 'translateY(0)'},
  '50%': {transform: 'translateY(-15px)'},
  '100%':  {transform: 'translateY(0)'}
})

const ScrollIcon = styled(motion.div)`
  justify-self: start;
  font-size: 0.9em;
  margin: 1rem 0 0 0;
  display: none;
  color: black;
  display: inline-block;
`

  const MainHeadingAnimated = ({props})  => {

    const [shadowX, setShadowX] = useState(0);
    const [shadowY, setShadowY] = useState(0);

    function moveShadow(e) {
      const walk = 20
      const { offsetWidth: width, offsetHeight: height } = e.target;
      const { clientY: y, clientX: x } = e;
      setShadowX(Math.round((x / width * walk) - (walk / 2)));
      setShadowY(Math.round((y / height * walk) - (walk / 2)));
    }

  return (
    <header>
      <Grid
        sx={{
          height: "100VH",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Heading
            sx={{
              transition: "all .3s ease",
              animationName: sparkle.toString(),
              animationTimingFunction: "ease-in-out",
              animationDelay: "0",
              animationDuration: "1s",
              animationIterationCount: "3",
              color: "white",
              fontSize: ["7rem", "10rem", "15rem"],
              lineHeight: ["7rem", "10rem", "15rem"],
              fontFamily: "Montserrat",
              fontWeight: "300",
              textShadow: `${shadowX}px ${shadowY}px 19px #ff6600, ${shadowX}px ${shadowY}px 20px #FF7233, ${shadowX}px ${shadowY}px 27px #ff7f66, 1px 5px 30px #ff8c99, -1px -5px 30px #ff99cc`,
            }}
            as="h1"
            onMouseMove={moveShadow}
          >
            tolka
          </Heading>
          <Flex
            sx={{
              opacity: "0",
              animationName: fadein.toString(),
              animationTimingFunction: "ease-in-out",
              animationIterationCount: "1",
              animationDuration: "3s",
              animationDelay: "1.5s",
              animationFillMode: "forwards",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Text
              as="h2"
              sx={{
                paddingLeft: "17px",
                paddingTop: "10px",
                textAlign: "center",
                fontFamily: "Montserrat",
                letterSpacing: ["0.4rem", "0.5rem", "0.7rem"],
                fontSize: ["1rem", "1.5rem", "2.5rem"],
                lineHeight: ["1rem", "1.5rem", "2.5rem"],
                color: "#fff",
                backfaceVisibility: "hidden",
                textShadow:
                  "0 0 19px #ff6600, 0 0 20px #FF7233, 0 0 27px #ff7f66, 1px 5px 30px #ff8c99, -1px -5px 30px #ff99cc",
              }}
            >
              web development
            </Text>
            <Flex
              sx={{
                padding: "40px",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <ScrollIcon
                aria-hidden="true"
                animate={{ y: 5 }}
                transition={{
                  yoyo: Infinity,
                  duration: 2,
                  ease: "easeInOut",
                  delay: 2,
                }}
              >
                <Text
                  sx={{
                    position: "absoute",
                    animationName: bounce.toString(),
                    animationDuration: "3s",
                    animationDelay: "2s",
                    animationIterationCount: "infinite",
                    animationTimingFunction: "alternate",
                    fontSize: ["3rem", "3.5rem", "4rem"],
                    color: "#fff",
                    backfaceVisibility: "hidden",
                    textShadow:
                      "0 0 19px #ff6600, 0 0 20px #FF7233, 0 0 27px #ff7f66, 1px 5px 30px #ff8c99, -1px -5px 30px #ff99cc",
                  }}
                >
                  &#10597;
                </Text>
              </ScrollIcon>
            </Flex>
          </Flex>
        </Box>
      </Grid>
    </header>
  )
}

export default MainHeadingAnimated
