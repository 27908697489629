/** @jsx jsx */
import { jsx, Grid, Text} from "theme-ui"
import Sticky from './sticky.js'
import colors from "../gatsby-plugin-theme-ui/colors"


export const scale = (num, outMin, outMax) => {
  return ((num - 0) * (outMax - outMin)) / (1 - 0) + outMin
}


const Words = props => (
  <Text
  {...props}
    sx={{
    textAlign: "center",
    fontSize: "2em",
    lineHeight: "3rem",
    fontFamily: "Montserrat",
    color: `${colors.white}`
  }}
  />
)

const Intro = props => {
  return (
    <Sticky
      height="2000px"
      background={colors.glow3}
      cover
      render={({ progress }) => {
        let fade = scale(progress, 0, 1)
        return (
          <Grid sx={{
            width: '100%',
            justifyContent: 'center',
          }}>
            <Words as='p' style={{ opacity: fade }} exit={{ opacity: 0 }}>
                  Hi there I'm Dee. <br/>
                  Front End Web Developer.
            </Words>
          </Grid>
        )
    }}
    />
  )
}

export default Intro
